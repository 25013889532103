import React, { useContext } from "react";
import "./Works.css";
import Upwork from "../../img/Upwork.png";
import Fiverr from "../../img/fiverr.png";
import Amazon from "../../img/amazon.png";
import Shopify from "../../img/Shopify.png";
import Facebook from "../../img/Facebook.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import {Link} from 'react-scroll'
const Works = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  return (
    <div className="works" id="works">
      {/* left side */}
      <div className="w-left">
        <div className="awesome">
          {/* dark Mode */}
          <span style={{ color: darkMode ? "white" : "" }}>
          My Skills
          </span>
          <span>Web Development and Data Analysis</span>
          <span>
           <b> Technologies oriented to the web development in frontend: </b>
          
            <br /> 
           HTML, CSS, Javascript, React, Bootstrap, SAAS, JQuery, Tailwind, Redux, Material UI,etc.
            
            <br />
            <br />
           <b>Technologies oriented to the web development in backend: </b>
            <br />
            NodeJS, Express,JSON, MongoDB, Mongoose, API, Rapid API, NextJs, etc.

            <span>
              
            <br/>
            <br/>  
           <span> <b>Technologies oriented to the Data Analysis</b> </span>
           <br/>
           D3, AJAX, Python, Pandas, SQL, DataBases, Excel, Python, NumPy, etc.


          </span>

          </span>
        
          <Link to="contact" smooth={true} spy={true}>
            <button className="button s-button">Hire Me</button>
          </Link>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        {/* right side */}
      </div>
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <img src={Upwork} alt="" width={100} />
          </div>
          <div className="w-secCircle">
            <img src={Fiverr} alt=""  width={100}/>
          </div>
          <div className="w-secCircle">
            <img src={Amazon} alt="" width={100} />
          </div>{" "}
          <div className="w-secCircle">
            <img src={Shopify} alt="" width={100} />
          </div>
          <div className="w-secCircle">
            <img src={Facebook} alt="" width={100} />
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle blueCircle"></div>
        <div className="w-backCircle yellowCircle"></div>
      </div>
    </div>
  );
};

export default Works;
