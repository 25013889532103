import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Sidebar from "../../img/sidebar.png";
import Ecommerce from "../../img/ecommerce.png";
import MusicApp from "../../img/musicapp.png";

import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
        <a href="https://poesianoapta.pages.dev/" target="blank">
          <img src={Sidebar} alt="Poetry site"/>
        </a>
        </SwiperSlide>
        <SwiperSlide>
        <a href="https://lucianoytclone.pages.dev/" target="blank">
          <img src={Ecommerce} alt="Youtube clone" />
         </a> 
        </SwiperSlide>
        <SwiperSlide>
        <a href="https://gustavounion.pages.dev/" target="blank">
          <img src={MusicApp} alt="Business portfolio" />
          </a>
          
        </SwiperSlide>
       
      </Swiper>
    </div>
  );
};

export default Portfolio;
